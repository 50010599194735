import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'; 
import "./SaleEssentialOilProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/essential_oil_01.webp";
import galleryImg2 from "../../../images/products/essential_oil_02.webp";
import galleryImg3 from "../../../images/products/essential_oil_03.webp";
import galleryImg4 from "../../../images/products/essential_oil_04.webp";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  },
  {
    original: galleryImg3,
    thumbnail: galleryImg3
  },
  {
    original: galleryImg4,
    thumbnail: galleryImg4
  }
];

export const SaleEssentialOilProduct = () => {
    const productName = "Illóolajok";

    const queryParams = new URLSearchParams(useLocation().search);  
    const userId = queryParams.get('userId');
    const firstname = (!queryParams.get('firstname') || queryParams.get('firstname') === "{{firstname}}") ? "" : queryParams.get('firstname');
    const lastname = (!queryParams.get('lastname') || queryParams.get('lastname') === "{{lastname}}") ? "" : queryParams.get('lastname');

    const navigate = useNavigate();

    const navigateToOrderDialog = () => {
    navigate('order?firstname=' + firstname + '&lastname=' + lastname + '&userId=' + userId);
    }

    useEffect(() => {
    document.title = 'ViaMedia - ' + productName + ' Akció';
    }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

        <h2>{productName}</h2>
        <p>Az aromaterápia során az illóolajokból egyedi illatkeverékeket hozhat létre, amelyeket parfümként viselhet vagy otthona illatosítására használhat. Az illóolajok nemcsak az idegrendszerre és az érzékszervekre gyakorolnak különleges hatást, hanem különböző tulajdonságaik révén számos betegség esetén is hatékonyan alkalmazhatók kiegészítő kezelésként.</p>
        <p></p>
        <p>Kiszerelés: 3x10ml</p>
        <p>Rózsa, Cseresznyevirág, Levendula</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Akciós árak és megrendelés</button>
        </div>

      </div>
  </div>

  );
};
